.height {
  height: 400px;
}
.border_red {
  border: 1px solid red;
}
.loader-grow {
  display: flex;
  align-items: center;
  justify-content: center;
}
.position-relative {
  position: relative;
}
.cursorPointer {
  cursor: pointer;
}
.loader-grow > .spinner-grow {
  width: 1rem;
  height: 1rem;
  margin: 0px 5px;
}
.font-10{
  font-size: 10px;
}
.btn-login {
  background-color: #e7bc00;
  border-color: #e7bc00;
  color: #ffffff;
  position: relative;
}

.btn-login span {
  margin-left: 5px;
}

.category-btn span {
  margin-left: 5px;
}

.btn-login:hover {
  background-color: #c3a009;
  border-color: #e7bc00;
  color: #ffffff;
}

.brand-clv {
  width: 40px !important;
}

.bg-auth {
  background-color: #e7bc00;
}

.text-primary-custom {
  color: #962424 !important;
  font-weight: 700;
  text-decoration: underline;
}

.file-form-label {
  font-size: 12.25px;
  font-weight: bolder;
  color: #777777;
}

.custom-form-check-label {
  font-size: 13px;
}

.form-control[type="file"] {
  padding-left: 10px;
  line-height: 2.4;
}

.back-arrow-container {
  margin-bottom: 25px;
}

.back-arrow-container a i.fa-arrow-left {
  font-size: 25px;
}
.back-arrow-container button{
  background-color: none;
  border: none;
  color: #777777;
}
.back-arrow-container button i.fa-arrow-left {
  font-size: 25px;
}
.inline-form-group-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.plus-btn {
  width: 46px;
  height: 46px;
  padding: 6px;
  background: transparent;
  color: #962424 !important;
  font-size: 20px;
  border: 1px solid #962424;
  border-radius: 0.625rem;
}

.plus-btn:disabled {
  opacity: 0.6;
}

.form-control-pattan {
  width: 120px;
  margin: 0px 5px;
}

.flex-1 {
  flex: 1;
}
.line-height-08{
  line-height: 0.8;
}
.upload-btn{
  width: 150px;
}
/********************Pagination*****************/
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.page-result p {
  margin: 0px;
}

.pagination-circle .page-item {
  margin-right: 0px;
  margin-left: 5px;
}

.pagination-circle .page-item .page-link {
  border-radius: 6px !important;
  color: #dc3545;
  border: none;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  background-color: #fff0ee;
  color: #dc3545;
  border: 1px solid #fca397;
}

.pagination .page-item:first-child .page-link:hover,
.pagination .page-item:last-child .page-link:hover {
  background-color: #dc3545;
}

/*************add-register**********/
.new-account p {
  margin: 0px;
}

.register-form {
  padding: 20px 30px;
}

.register-form img {
  width: 180px;
}

.register-form-error {
  color: #f75a5b;
  /* position: absolute;
    top: 0px; */
}

/* .register-form .form-group {
    position: relative;
} */
.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

/******************Custom-input-type***************/
.multi-Image-View {
  height: 100px;
  position: relative;
}

.custom-file-input {
  position: absolute;
  top: 5px;
  right: 5px;
}

.custom-file-input label {
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-img-preview {
  display: flex;
}

.img-preview-item {
  position: relative;
}

.img-preview-item img {
  width: 100px;
  height: 60px;
  margin-right: 10px;
}

.img-preview-item button {
  position: absolute;
  top: 15px;
  left: 35px;
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.img-preview-item button i {
  font-size: 16px;
}

.upload-image-wrapper {
  position: relative;
}

.upload-container {
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 0px;
}

.image-item {
  width: 100px;
  position: relative;
  padding: 0px 5px;
}

.image-item img {
  width: 100px;
  height: 60px;
}

.image-item button {
  position: absolute;
  top: 10px;
  left: 34px;
  background: transparent;
  padding: 5px;
  border-radius: 20px;
  border: none;
}

.image-item button i.fa-regular {
  font-size: 24px;
  color: #962424;
}

.upload-container-view {
  display: flex;
  align-items: center;
}

.image-preview-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-preview-btn button {
  background-color: #962424;
  padding: 5px 10px;
  border: none;
  color: #fff;
  font-size: 12px;
}

.img-modal-text-view {
  width: 80px;
}

.basic-dropdown {
  position: relative;
}

.drop_down_arrow {
  float: right;
  position: absolute;
  top: 17px;
  right: 15px;
  color: #6e6e6e;
  font-size: 11px;
  pointer-events: none;
}

.content_status {
  padding: 5px;
  align-self: center;
  width: fit-content;
  border-radius: 5px;
}

.pending_background {
  background-color: #ff6a59;
}

.assian_validator_background {
  background-color: #4cbc9a;
}

.validator_approved_background {
  background-color: #56c760;
}

.assian_designer_background {
  background-color: #4cbc9a;
}

.designer_uploaded_background {
  background-color: #58bad7;
}

.admin_publish_background {
  background-color: #56c760;
}

.fwhite {
  color: #fff;
}

.basic-details-item {
  margin: 15px 0px;
}

.basic-details-item label {
  font-weight: 600;
  font-size: 14px;
}

.basic-details-item span {
  font-size: 14px;
  padding-left: 2px;
}
.basic-files-details{
  display: flex;
}
.basic-files-details-item{
  display: flex;
  flex-direction: column;
}
.basic-files-details-item a {
  font-size: 14px;
  padding-left: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #0c5bff;
}
.basic-files-details-item a:hover {
  color: #962424 !important;
}
.basic-details {
  background: #fff;
  box-shadow: 0px 0px 5px #c3c0c0;
  border-radius: 6px;
  padding: 20px;
}

.select-validator-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.select-validator-section select {
  width: 100%;
}

.select-validator-section label {
  width: 120px;
}

.validator-btn {
  margin-left: 10px;
  width: 170px;
  height: 45px;
}

.path_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.pdf_right_side {
  width: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf_page_input {
  width: 65px;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.pdf_right_side button {
  border: none;
  background-color: transparent;
}

.active-editor-btn {
  background-color: #4cbc9a !important;
}

.inactive-editor-btn {
  background-color: #dadada !important;
  color: #6e6e6e !important;
}

.radius-lft {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.image-preview-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tab-btn-view {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 5px;
}

.tab-btn-view div {
  width: 100%;
  background-color: #962424;
  padding: 8px 10px;
  border: none;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-btn-view button {
  width: 200px;
  height: 50px;
  background-color: transparent;
  color: #777777;
  border: 1px solid #dbdbdb;
  border-bottom: none;
}

.editor-preview-btn {
  background-color: #962424;
  border-radius: 4px;
  padding: 8px 10px !important;
  border: none;
  color: #fff;
  font-size: 12px;
}

.drop_down_arrow {
  float: right;
  position: absolute;
  top: 14px;
  right: 15px;
  color: #777777;
  font-size: 13px;
  pointer-events: none;
}

.drop_down_arrow_disable {
  float: right;
  position: absolute;
  top: 10px;
  right: 15px;
  color: #0c5576;
  font-size: 13px;
  pointer-events: none;
}

.position_relative {
  position: relative;
}

/*new styles added - Dipanjan Munshi - 25Aug23*/
.toggle-radio-button-group {
  background: var(--rgba-primary-1);
  border: 1px solid var(--primary);
  border-radius: 5px;
  display: flex;
  align-items: stretch;
  position: relative;
  color: var(--primary);
}

.toggle-radio-button-group input[type="radio"] {
  opacity: 0;
  position: absolute;
}

.toggle-radio-button-group label {
  margin-bottom: 0;
  display: flex;
  align-items: stretch;
}

.toggle-radio-button-group label + label {
  border-left: 1px solid var(--primary);
}

.toggle-radio-button-group span {
  width: 80px;
  display: flex;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
}

.cursor_pointer {
  cursor: pointer;
}

.toggle-radio-button-group .radio-reject input:checked + span {
  background-color: var(--primary);
  color: #fff;
  font-size: 0;
}

.toggle-radio-button-group .radio-reject input:checked + span::after {
  content: "Rejected";
  font-size: 12px;
  cursor: auto;
  display: block;
}

.toggle-radio-button-group .radio-approve input:checked + span {
  background-color: #56c760;
  color: #fefefe;
  font-size: 0;
}

.toggle-radio-button-group .radio-approve input:checked + span::after {
  content: "Approved";
  font-size: 12px;
  cursor: auto;
  display: block;
}

.btn-edit {
  border: 1px solid var(--primary) !important;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  margin: auto;
}

.lbl-assigned {
  cursor: default;
}
.cursor-auto {
  cursor: auto !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.filter-dropdown {
  min-width: 135px;
}

input[disabled],
select[disabled],
textarea[disabled] {
  background-color: #f4f4f4 !important;
}

.filter-row-max-width {
  max-width: 1000px;
}

/*drag-n-drop file upload */

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: grey;
}

.box__file {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  top: 100%;
  opacity: 0;
}

.upload-button {
  display: block;
  position: relative;
  background-color: #025bee;
  color: #ffffff;
  font-size: 1.1em;
  text-align: center;
  width: 16em;
  padding: 1em 0;
  border-radius: 0.3em;
  margin: 0 auto 1em auto;
  cursor: pointer;
}

.file-input-container {
  background-color: #ffffff;
  width: 100%;
  min-width: 27.5em;
  padding: 3.12em 1.87em;
  box-shadow: 0 1.25em 3.43em rgba(0, 0, 0, 0.08);
  border-radius: 0.5em;
  border: 0.2em dashed #ccc;
  transition: all 0.1s;
}

.file-input-container.active {
  border: 0.3em dashed #025bee;
  background-color: #ccc;
}

.status-btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

#error {
  text-align: center;
  color: #ff3030;
}

.link {
  color: var(--primary) !important;
}

.file-viewer {
  background: #fff;
  padding: 0px;
  border-radius: 0.5em;
  border: 0.2em dashed #ccc;
  display: flex;
}

.file-icon-wrapper {
  align-items: stretch;
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.file-icon-wrapper > i {
  font-size: 2rem;
  color: var(--primary);
}

.file-viewer span {
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
}

.file-viewer a {
  margin: 10px;
}

.position_relative {
  position: relative;
}

.item_disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}

.loading-view {
  width: 100%;
  height: 100vh;
  background-color: #00000063;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon-view {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentlist-filter-view {
  width: 100%;
}

.reset-btn-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-button-reset {
  padding: 10px 20px;
  margin-right: -10px;
  margin-bottom: 10px;
}

.user_name {
  font-size: 10px;
  margin-top: 5px;
  text-transform: capitalize;
  white-space: pre-line;
  line-height: 10px;
}

.content-details {
  padding: 30px 30px;
}

.content-details-btn-groups {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-submit-btn {
  width: 140px;
}

/**************************profile**********************/
.dropdown-item i {
  color: #962424;
  font-size: 16px;
}

.password-form-view {
  position: relative;
}

.password-form-view button {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  height: 45px;
  width: 45px;
  background: transparent;
  font-size: 15px;
  color: #dbdbdb;
}

.profile-container {
  width: 100%;
}

.profile-header-image {
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
}

.profile-header-image img {
  width: 100%;
  height: 100%;
  border-radius: 50px !important;
}

.profile-form-content {
  margin-top: 30px;
}

.profile-update-btn {
  background-color: #8b0d0e;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-update-btn:hover {
  background-color: #8b0d0e;
  color: #ffffff !important;
}

.profile-update-btn .spinner-border {
  margin-left: 5px;
  border-width: 3px;
}

.profile-form-content {
  margin-bottom: 20px;
}

.profile-custom-file-input {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #8b0d0e;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-custom-file-input label {
  margin: 0px;
  cursor: pointer;
}

.profile-custom-file-input label i {
  font-size: 14px;
  color: #ffffff;
}

.sc-aXZVg {
  min-width: 100% !important;
  max-width: 100% !important;
  height: 148px !important;
  background-color: #eae6e6;
}

.sc-aXZVg svg {
  display: none;
}

.sc-eqUAAy {
  width: 40% !important;
  justify-content: center !important;
}

/* .sc-eqUAAy {
    width: 40% !important;
    justify-content: center !important;
    background-color: #fdd5d0;
    height: 130px !important;
    justify-content: center;
    align-items: center;
} */

.sc-eqUAAy span.file-types {
  display: none;
}

.sc-eqUAAy span.file-types {
  display: none;
}

.mandatory_color {
  color: red;
}

.no_data {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

.col_heading {
  font-size: 14px !important;
  /* border:1px solid red; */
  font-family: "Arial Narrow";
}

.chapter_col {
  white-space: pre-wrap !important;
}

.remove_btn {
  border: 1px solid #962424 !important;
  border-radius: 6px;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  margin: auto;
  background-color: #ff6a59;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}

.box__input {
  position: relative;
}

.tox-notifications-container,
.mce-notification {
  display: none !important;
}

.notification_counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  border-radius: 30px;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 5px;
}
.questions-details-item {
  display: flex;
  flex-direction: column;
}
.questions-details-item span {
  padding-bottom: 10px;
}
.display-flex {
  display: flex;
}
.bulk-qus-modal-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bulk-qus-modal-excel-view {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.bulk-tem-btn-view span {
  color: #56c760;
  margin-right: 3px;
}
.bulk-tem-btn-view a {
  font-weight: 600;
}
.submit-modal-btn {
  display: flex;
  align-items: center;
}
.submit-modal-btn span {
  margin-left: 5px;
}
.radius-lft {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.textarea-container {
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}
.textarea-container .chra-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: #962424;
  font-family: sans-serif;
  border-radius: 50px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.qws-request-btn-group {
  display: flex;
  width: 75px;
  margin: auto;
}
.btn-delete-dam:disabled {
  opacity: 0.6;
  cursor: none;
}
.spinner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-btn span {
  margin-left: 5px;
}
.icon_right {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  height: 45px;
}
.icon_right i.fa{
font-size: 16px;
}
.elibrary_pdf_page_input {
  width: 50px;
  padding: 2px 5px;
  text-align: center;
}
.pdf_right_side {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.earning-container{
  background-color: #ffe3e3;
  border-radius: 6px;
  padding: 15px;
  margin-top: -15px;
}
.earning-container h3{
  font-size: 28px;
  font-weight: 600;
  margin: 0px;
}
.earning-container span{
  font-size: 12px;
}
.earning-container h4{
  font-size: 14px;
  font-weight: 600;
  color: #8d068d;
  margin-top: -5px;
}
.date-control-view {
  position: relative;
}

.date-control-view .react-datepicker-wrapper {
  width: 100%;
}

.date-control-view .react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  background: #fff;
  color: #6e6e6e;
  line-height: 2.4;
  font-size: 1rem;
  border-radius: 0.625rem;
  border: 1px solid #dbdbdb;
  appearance: none;
}
.react-datepicker-popper {
  z-index: 9;
}
.clear-date-btn-align {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  line-height: 0px;
  position: absolute;
  top: 8px;
  right: 40px;
  margin-top: 5px;
}
.picker-btn {
  background-color: transparent !important;
  position: absolute;
  top: 14px;
  right: 10px;
  border: none;
}

.picker-btn i {
  color: #6e6e6e;
  font-size: 16px;
}
.filter_btn {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7ae47440;
  cursor: pointer;
  border-radius: 0.625rem !important;
  font-size: 12px;
}
.filter_btn_view p{
  font-weight: 600;
  color: #000;
  margin: 0px;
  padding-left: 5px;
}
.image_container {
  width: 18px;
  height: 18px;
  justify-content: "center";
  align-items: "center";
}

.image_style {
  width: 100%;
  height: 100%;
}
.question-modal-form {
  padding: 15px 25px 25px 25px;
}
.reset-filtter-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.content_disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}
.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
}
.pdf_fullScreen {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.top_back_container_pdf {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
  font-weight: 600;
  cursor: pointer;
}
.back_arrow {
  color: #2d6179;
  font-size: 18px;
  margin-right: 5px;
  font-weight: bold;
}

.pdf_right_side_container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  color: #2d6179;
}
.cursor_context_menu {
  cursor: context-menu;
}
.text_blue {
  color: #245c75;
}

.icon_description {
  margin-left: 5px;
}
.App {
  text-align: center;
}

.container-disable {
  opacity: 0.6;
  pointer-events: none;
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
  -o-filter: grayscale(1);
}